<template>
  <div class="container-page">

    <div class="head" :class="'header-'+currentUrl">
      <div class="logo">
        <img :src="this.$website.logo">
        <h1 class="name">{{this.$website.title}}</h1>
      </div>
      <div class="menu">
        <a :href="item.link" title="" v-for="(item,index) of menuList" :key="index" :class="{active:currentUrl==item.type}">{{item.name}}</a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "menuComponentIndex",
  props:{
  },
  components: {

  },
  data() {
    return {
      currentUrl:'',
      menuList:[
        {name:'首页',link:'/',type:'home'},
        {name:'下载',link:'/download',type:'download'},
        {name:'智能产品',link:'/product',type:'product'},
        {name:'联系我们',link:'/about',type:'about'},
      ],
    };
  },
  mounted() {
      this.currentUrl = this.$route.path.replace('/','')||'home'
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      display: flex;
      align-items: center;
      color: #fff;
      img{
        width: 40px;
        height: 40px;
      }
      .name{
        padding-left: 10px;
        font-size: 36px;
        font-weight: 500;
      }
    }
    .menu{
      a{
        display: inline-block;
        width: 120px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        border-radius: 23px;
        text-decoration: none;
        color: #fff;
        margin-left: 20px;
        &.active{
          background: #FFFFFF;
          color: #3D67F1;
        }
      }
    }
    &.header-about{
      .logo{
        color: #252440;
      }
      .menu{
        a{
          color: #252440;
          &:hover{
            color: #3D67F1;
          }
          &.active{
            color: #3D67F1;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .head{
      .logo{
        .name{
          font-size: 18px;
        }
      }
      .menu{
        a{
          width: auto;
          height: auto;
          line-height: normal;
          padding: 8px 20px;
        }
      }
    }
  }
  @media screen and (max-width: 767px){
    .head{
      .logo{
        .name{
          display: none;
        }
        img{
          width: 30px;
          height: 30px;
        }
      }
      .menu{
        a{
          width: auto;
          height: auto;
          line-height: normal;
          padding: 2px 10px;
          font-size: 12px;
          margin-left: 0;
        }
      }
    }
  }

</style>
