<template>
  <div class="container-page">

    <!-- 底部 -->
    <div class="section-page footer" :style="{backgroundImage:'url('+footerBgImg+')'}">
      <div class="container">

        <div class="footer-info">

          <div class="about-link">
            <strong class="tit">关于网站</strong>
            <a :class="{first:index==0}" :href="item.link" v-for="(item,index) of links" :key="index">{{item.name}}</a>
          </div>

          <div class="footer-service">
            <div class="grid">
              <div class="sub-grid">
                <strong class="tit">{{this.$website.addressService[0].title}}</strong>
                <p class="cont">{{this.$website.addressService[0].info}}</p>
              </div>
              <div class="sub-grid">
                <strong class="tit">{{this.$website.addressService[2].title}}</strong>
                <p class="cont">{{this.$website.addressService[2].info}}</p>
              </div>
            </div>
            <div class="grid">
              <strong class="tit">{{this.$website.addressService[1].title}}</strong>
              <p class="cont">{{this.$website.addressService[1].info}}</p>
            </div>
            <div class="grid">
              <strong class="tit">{{this.$website.addressService[3].title}}</strong>
              <p class="cont">{{this.$website.addressService[3].info}}</p>
            </div>
          </div>

          <div class="qr-code">
            <div class="grid">
              <img :src="this.$website.qrCode">
              <p class="txt">扫码联系我们</p>
            </div>
          </div>

        </div>

        <div class="copyright">联系电话：{{this.$website.contactPhone}} <a href="https://beian.miit.gov.cn/" target="_blank">{{this.$website.recordNo}}</a></div>



      </div>
    </div>
    <div style="margin:0 auto; padding:20px 0; background: #1f1f42">
      <div class="container">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52010202003287" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="../assets/gongan.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">贵公网安备 52010202003287号</p>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

  //
  import footerBgImg from '@/assets/footer-bg.png'


export default {
  name: "footerComponentIndex",
  components: {

  },
  data() {
    return {
      footerBgImg,
      links:[
        {name:'网站首页',link:'/'},
        {name:'下载软件',link:'/download'},
        {name:'智能产品',link:'/product'},
        {name:'关于我们',link:'/about'},
      ],

    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>


  /* 底部 */
  .footer{
    height: 300px;
    background: rgba(31, 31, 66, 1);
    .footer-info{
      display: flex;
      justify-content: space-between;
      .about-link{
        display: flex;
        flex-direction: column;
        .tit{
          font-size: 16px;
          color: #868693;
          font-weight: 400;
        }
        a{
          display: block;
          margin-top: 25px;
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          &.first{
            margin-top: 15px;
          }
        }
      }
      .footer-service{
        display: flex;
        flex-direction: column;
        width: 40%;
        .grid{
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          text-align: left;
          .sub-grid{
            width: 50%;
          }
          .tit{
            font-size: 16px;
            color: #868693;
            font-weight: 400;
          }
          .cont{
            margin-top: 10px;
            font-size: 14px;
            color: #fff;
          }
          &:first-child{
            margin-top: 0;
            flex-direction: row;
          }
        }
      }
      .qr-code{
        .grid{
          display: flex;
          flex-direction: column;
          img{
            width: 140px;
            height: 140px;
          }
          .txt{
            margin-top: 10px;
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    .copyright{
      margin-top: 20px;
      height: 48px;
      line-height: 48px;
      border-top: 2px solid #505055;
      font-size: 14px;
      color: #868693;
      a{
        color: #868693;
        padding-left: 30px;
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .footer{
      .footer-info{

      }
    }
  }
  @media screen and (max-width: 767px) {
    .footer{
      height: auto;
      .footer-info{
        display: block;
        .about-link{

        }
        .footer-service{
          margin-top: 20px;
          width: 100%;
          .grid{
            text-align: center;
            display: block;
            .sub-grid{
              width: 100%;
            }
          }
        }
        .qr-code{
          margin-top: 20px;
          .grid{
            align-items: center;
            justify-content: center;
          }
        }
      }
      .copyright{
        font-size: 12px;
      }
    }
  }
</style>
