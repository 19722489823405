import http from '../utils/https'

/**
 * 获取软件最后一次软件
 * @param {}
 */
export const listHardwareVersion = () => {
    return http.get('/update/listHardwareVersion?appName=stone_desktop',);
}
/**
 * 合作与了解
 * @param {Object}
 */
export const updateLeaveMessage = (data) => {
    return http.post('/update/leaveMessage',data);
}
