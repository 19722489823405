<template>
  <div class="container-page">

    <div class="section-page banner"
         :style="{backgroundImage:'url('+bannerImg+')'}">
      <div class="container">

        <menu-component-index></menu-component-index>

        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>

import MenuComponentIndex from "./menu";
export default {
  name: "headerComponentIndex",
  props:{
    bannerImg:{
      type:String,
      default:''
    }
  },
  components: {
    MenuComponentIndex

  },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  .banner {
    height: 800px;
    background-size: cover;
    background-color: #3767F1;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .banner{
      height: 600px;
    }
  }
  @media screen and (max-width: 767px){
    .banner{
      height: 400px;
    }
  }
</style>
