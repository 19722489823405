<template>
  <div class="container-page">

    <!-- 合作与了解 -->
    <div class="section-page contact">
      <div class="container">
        <h3 class="h3">合作与了解</h3>
        <p class="sub-tit">想加入公司或者联系我们，请留下电话与邮箱与我们联系</p>

        <div class="contact-form">
          <div class="grid">
            <div class="sub-grid">
              <input type="text" v-model="updateForm.user_name" placeholder="姓名">
            </div>
            <div class="sub-grid">
              <input type="tel" v-model="updateForm.user_phone" maxlength="12" placeholder="电话">
            </div>
            <div class="sub-grid">
              <input type="email" v-model="updateForm.user_mail" placeholder="邮箱">
            </div>
          </div>

          <div class="grid">
            <textarea maxlength="500" v-model="updateForm.message"  placeholder="您的需求"></textarea>
          </div>
          <div class="grid button-grid">
            <p class="error-msg" ><i class="el-icon-warning-outline" v-if="errorMsg"></i> {{errorMsg}}</p>
            <el-button type="button" :loading="submitDisabled"  @click="saveForm" class="btn">提交</el-button>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>


  // api
  import {
    updateLeaveMessage
  } from '@/module/common.js'

export default {
  name: "contactComponentIndex",
  components: {
  },
  data() {
    return {
      updateForm:{
        user_name:'',
        user_phone:'',
        user_mail:'',
        message:'',
      },
      errorMsg:'',
      submitDisabled:false,
    };
  },
  methods: {
    // 保存
    saveForm(){
      let _self = this

      // 校验
      if(!this.updateForm.user_name){
        this.errorMsg = '请输入您的姓名'
        return
      }
      if(!this.updateForm.user_phone){
        this.errorMsg = '请输入您的电话'
        return
      }
      if(!this.updateForm.user_mail){
        this.errorMsg = '请输入您的邮箱'
        return
      }
      if(!this.updateForm.message){
        this.errorMsg = '请输入您的需求'
        return
      }

      this.submitDisabled = true

      // FormData 转换
      let formData = new FormData();
      formData.append('user_name', this.updateForm.user_name)
      formData.append('user_phone', this.updateForm.user_phone)
      formData.append('user_mail', this.updateForm.user_mail)
      formData.append('message', this.updateForm.message)

      updateLeaveMessage(formData).then(res=>{
        _self.submitDisabled = false
        if(res.dataObj){
          _self.$message.success('您的信息已提交，稍后会有工作人员与你联系！谢谢！')
          _self.errorMsg = ''
          _self.updateForm.user_name = ''
          _self.updateForm.user_phone = ''
          _self.updateForm.user_mail = ''
          _self.updateForm.message = ''
        }
      }).catch(err=>{
        _self.$message.error(err.data.dataMsg)
        _self.submitDisabled = false
      })
    },
  }
};
</script>

<style lang="scss" scoped>

  /* 合作与了解 */
  .contact{
    background: #F8FAFD;
    .contact-form{
      margin: 30px auto;
      width: 50%;
      .grid{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .sub-grid{
          width: 30%;
          input{
            width: 100%;
            height: 50px;
            padding: 5px 20px;
            background: #FFFFFF;
            box-shadow: 0px 2px 15px 2px rgba(61, 103, 241, 0.08);
            border-radius: 6px;
            border: 0;
            &:focus{
              outline: none;
            }
          }
        }
        textarea{
          width: 100%;
          height: 187px;
          padding: 5px 20px;
          background: #FFFFFF;
          box-shadow: 0px 2px 15px 2px rgba(61, 103, 241, 0.08);
          border-radius: 6px;
          border: 0;
          resize: none;
          &:focus{
            outline: none;
          }
        }
        button{
          display: inline-block;
          padding: 0;
          width: 120px;
          height: 50px;
          line-height: 50px;
          background: #3D67F1;
          border-radius: 6px;
          font-size: 16px;
          color: #FFFFFF;
          border: 0;
          &:focus{
            outline: none;
          }
        }
        &.button-grid{
          // margin-top: 20px;
          flex-direction: column;
          justify-content: center;
          .error-msg{
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            text-align: left;
            color: #fd3e3e;
          }
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .contact{
      .contact-form{
        width: 90%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .contact{
      .contact-form{
        width: 100%;
        .grid{
          display: block;
          .sub-grid{
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
</style>
